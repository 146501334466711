import React from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Breadcrumb from "../components/Breadcrumb"
import TitleBlock from "../components/TitleBlock"
import TitlePage from "../components/TitlePage"

export default function Location({ location }) {
  const path = [
    { label: "Inicio", to: "/" },
    { label: "Contacto - ¿Dónde estamos?" },
  ]
  return (
    <Layout>
      <TitlePage title="¿Dónde estamos?" url={location.pathname} />
      <Hero image="/imgs/contact-hero.jpg" text="Contacto" />
      <div className="container950">
        <Breadcrumb path={path} />
        <TitleBlock
          title="¿Dónde estamos?"
          icon="fas fa-map-marked-alt"
          className="mb-4"
        />
        <iframe
          width="100%"
          height="450"
          loading="lazy"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11972.916171827228!2d2.143277!3d41.390832!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a498784fd0c55b%3A0xe3ef2dd6459a3908!2sAv.%20de%20Josep%20Tarradellas%2C%20127%2C%2008029%20Barcelona%2C%20Espa%C3%B1a!5e0!3m2!1ses!2sni!4v1614783778143!5m2!1ses!2sni"
          frameborder="0"
          title="location map"
        ></iframe>
        <span className="Location-address">
          Avda. Josep Tarradellas nº. 123-127, 2ª Planta 08029 Barcelona
        </span>
        <span className="Location-phone">Tel: +34 93.412.10.08</span>
        <a
          className="Location-link"
          href="mailto:&#105;&#110;&#102;&#111;&#114;&#109;&#097;&#099;&#105;&#111;&#110;&#064;&#101;&#102;&#112;&#097;&#046;&#101;&#115;"
        >
          E-mail: informacion@efpa.es
        </a>
      </div>
    </Layout>
  )
}
